import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { css } from '@emotion/css';
import { SoftwareDetail as SD } from '../../components/softwareui';
import { CodeLink as CL } from '../../components/softwareui';
import { Link } from 'gatsby';
import { LINKED_LIST } from '../../types/software';
import { LinkedListMethodLink as Fll } from '../../components/softwareui';
import { DEEPMESA_VERSION } from '../../types/software';
import { LinkedListChart } from '../../components/benchmarks/linkedlist/linkedlist';

const LinkedList = () => {
    return (
        <SD item={LINKED_LIST}>
            <p>
                {LINKED_LIST.desc}
            </p>

            <p>
                The API is the same as <CL href="https://doc.rust-lang.org/std/collections/struct.LinkedList.html">std::collections::LinkedList</CL> however
                this list also allows pushing and popping elements from the middle
                of the list in constant time.
            </p>

            <p>
                This list also vends handles to its nodes that can be used to
                mutate the list in constant time.
            </p>

            <SD.SubHeader text="Getting Started" divider id="getting_started" />
            <p>
                To get started add the deepmesa dependency to Cargo.toml and the
                use declaration in your source.
            </p>
            <SD.Code>{`[dependencies]
deepmesa = "${DEEPMESA_VERSION}"`}
            </SD.Code>
            <SD.Code>
                {`use deepmesa::lists::LinkedList;

let mut list = LinkedList::<u8>::with_capacity(10);
for i in 0..10 {
    list.push_front(i);
}

for e in list.iter() {
    println!("{}", e);
}`}
            </SD.Code>

            <SD.SubHeader text="Performance" divider id="performance" />
            <p>
                Benchmarks against std::LinkedList show a performance gain of almost 2x in push operations when memory is allocated upfront.
            </p>
            <LinkedListChart name="push_front" />
            <br />
            <p>
                Similar results are observed in pop operations as well.
            </p>
            <LinkedListChart name="pop_front" />
            <br />
            <p>
                For a more in-depth view see the <Link to="/benchmarks/linkedlist/" rel="noopener noreferrer">full performance graphs</Link>.
            </p>

            <SD.SubHeader text="Memory Management" divider id="mem_mgmt" />
            <p>
                This list manages memory via an internal freelist of nodes. When a new element is added to the list, a preallocated node is acquired from the freelist. When an element is removed from the list, the node is returned to the freelist. This ensures that memory is not allocated and deallocated on every push and pop which makes the list fast.
            </p>

            <p>
                All memory for the list is allocated on the heap using the default
                allocator. Additional memory is allocated by the freelist when a
                new element is added to the list and the capacity is filled.
            </p>

            <p>
                When the list is dropped, all memory is deallocated and any
                elements stored in the list are dropped. If the <CL href="https://doc.rust-lang.org/std/ops/trait.Drop.html">Drop</CL> trait on
                an element panics the list will deallocate all allocated memory
                because elements are removed from the list and dropped only after
                all memory is deallocated.
            </p>

            <SD.SubHeader text="Capacity & Reallocation" divider id="cap_realloc" />
            <p>
                The capacity of the list is the number of elements it can hold
                before allocating new memory. The length of the list is the number
                of elements it holds. When the length equals the capacity, and a
                new element is added to the list, the list will allocate
                additional memory.
            </p>
            <p>
                The amount of memory allocated when the capacity is exhausted
                depends on how the list is constructed. If the list is constructed
                using <Fll href="new">new()</Fll> or <Fll href="with_capacity">with_capacity()</Fll> with a non-zero capacity
                then the capacity is doubled on every allocation.
            </p>
            <p>
                If the list is constructed using <Fll href="with_capacity">with_capacity()</Fll> with a capacity of
                zero, then the list will not preallocate any memory on
                construction. In this case, when a new element is added to the
                list, additional memory will be allocated for the new elememt
                unless the freelist has available memory from previous remove
                operations.
            </p>
            <SD.Code>{`use deepmesa::lists::LinkedList;
// create a list with capacity 0
let mut list = LinkedList::<u8>::with_capacity(0);
assert_eq!(list.len(), 0);
assert_eq!(list.capacity(), 0);
// Pushing elements will cause an allocation every time
for i in 0..10 {
    assert_eq!(list.len(), i);
    assert_eq!(list.capacity(), i);
    list.push_head(1);
}

// Removing an element will not cause a deallocation
list.pop_head();
assert_eq!(list.len(), 9);
assert_eq!(list.capacity(), 10);

// Now that capacity exceeds the length of the list no memory will
// be allocated unless existing capacity is exhausted
list.push_head(1);
assert_eq!(list.len(), 10);
assert_eq!(list.capacity(), 10);
// any further additions to the list will again allocate new
// memory for each element added.
list.push_head(1);
assert_eq!(list.len(), 11);
assert_eq!(list.capacity(), 11);`}
            </SD.Code>
            <p>
                It is recommended to use <Fll href="with_capacity">with_capacity()</Fll> whenever possible and specify how big the list is expected to get.
            </p>
            <SD.SubHeader text="Handles" divider id="handles" />
            <p>
                The <Fll href="push_head">push_head()</Fll>, <Fll href="push_tail">push_tail()</Fll>, <Fll href="push_next">push_next()</Fll> and <Fll href="push_prev">push_prev</Fll> methods return handles to the nodes pushed to the linked list. The handles are implemented as structs of type <CL href="https://docs.rs/deepmesa/0.1.0/deepmesa/lists/linkedlist/struct.Node.html">Node&lt;T&gt;</CL> that wrap a raw pointer to node. However since <CL href="https://docs.rs/deepmesa/0.1.0/deepmesa/lists/linkedlist/struct.Node.html">Node&lt;T&gt;</CL> does not implement the <CL href="https://doc.rust-lang.org/std/ops/trait.Deref.html">Deref</CL> trait, these raw pointers cannot be dereferenced directly. Handles
                can only be used by passing them as arguments to the <Fll href="next">next()</Fll>, <Fll href="next_mut">next_mut()</Fll>, <Fll href="prev">prev()</Fll>, <Fll href="prev_mut">prev_mut()</Fll>, <Fll href="prev_node">prev_node()</Fll>, <Fll href="next_node">next_node()</Fll>, <Fll href="node">node()</Fll>, <Fll href="node_mut">node_mut()</Fll>, <Fll href="has_next">has_next()</Fll>, <Fll href="has_prev">has_prev()</Fll>, <Fll href="pop_next">pop_next()</Fll>, <Fll href="pop_prev">pop_prev()</Fll>, <Fll href="pop_node">pop_node()</Fll>, <Fll href="push_next">push_next()</Fll>, <Fll href="push_prev">push_prev()</Fll>, allows adding, removing and mutating elements in the middle of the list in O(1) time.
            </p>
            <p>
                Handles can be copied, cloned and passed around by value or
                reference without regard to the lifetime of the list. When an
                element is removed from the list, the handle associated with that
                node becomes invalid forever. Passing an invalid handle to the
                list is safe since all methods that accept a reference to a handle
                return None if the handle is invalid.
            </p>
            <SD.Code>{`use deepmesa::lists::LinkedList;
let mut list = LinkedList::<u8>::with_capacity(10);
list.push_head(1);
let middle = list.push_head(100);
list.push_head(2);
// get the value of the node in the middle of the list in O(1)
// time.
assert_eq!(list.node(&middle), Some(&100));
// remove the middle node in O(1) time
list.pop_node(&middle);
// once the middle node is removed, the handle is invalid
assert_eq!(list.node(&middle), None);
assert_eq!(list.len(), 2);`}
            </SD.Code>
            <p>
                <CL href="https://docs.rs/deepmesa/0.1.0/deepmesa/lists/linkedlist/struct.Node.html">Node&lt;T&gt;</CL> implements the <CL href="https://doc.rust-lang.org/std/default/trait.Default.html">Default</CL> trait so you can store default (invalid) handles in a struct and assign them later.
            </p>
            <SD.Code>{`use deepmesa::lists::LinkedList;
use deepmesa::lists::linkedlist::Node;
struct MyStruct<T> {
    handle: Node<T>
};
let mut s = MyStruct::<u8> {
    handle: Node::<u8>::default()
};
let mut list = LinkedList::<u8>::with_capacity(10);
// The default handle is invalid
assert_eq!(list.node(&s.handle), None);
// push a new element and store the handle
s.handle = list.push_head(1);
assert_eq!(list.node(&s.handle), Some(&1));`}
            </SD.Code>
            <SD.SubHeader text="Iterators" divider id="iterators" />
            <p>
                The list supports iterators that can traverse the list in either
                direction by reversing the iterator at any time.
            </p>
            <SD.Code>{`use deepmesa::lists::LinkedList;
            let mut list = LinkedList::&lt;u8&lt;::with_capacity(10);
for i in 0..10 {
    list.push_head(i);
}
let mut iter = list.iter();
assert_eq!(iter.next(), Some(&9));
assert_eq!(iter.next(), Some(&8));
assert_eq!(iter.next(), Some(&7));
//now reverse the iterator
iter = iter.reverse();
assert_eq!(iter.next(), Some(&8));
assert_eq!(iter.next(), Some(&9));
assert_eq!(iter.next(), None);`}
            </SD.Code>


        </SD>
    );
}

export default LinkedList;

///////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////

const perfImgClass = css({
    backgroundColor: 'LightGray!important',
    marginLeft: '30px',
    marginTop: '30px',
    marginBottom: '30px',
    width: '90%',
});
